import React, { useEffect, useState } from "react";
import { updateTask } from "./firebaseFunctions";
import {
  VideoTask,
  TaskType,
  TaskStatus,
  VideoLength,
  VoiceType,
} from "../types";
import { useUser } from "../contexts/UserContext"; // UserContext 사용
import TimeInput from "./TimeInput";
import styles from "./AddTask.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import LoadingSpinner from "./LoadingSpinner";
import { useTranslation } from "react-i18next"; // useTranslation 훅 추가

const EditTask = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language; // 현재 설정된 언어 가져오기
  const location = useLocation();
  const orgTask = location.state?.orgTask as VideoTask;
  const [isEditing, setIsEditing] = useState(false);
  const [newBest, setNewBest] = useState("");
  const [taskType, setTaskType] = useState<TaskType>(TaskType.TextToVideo);
  const [videoLength, setVideoLength] = useState<VideoLength>(
    VideoLength.Short
  );
  const [videoUrl, setVideoUrl] = useState("");
  const [cropStartTime, setCropStartTime] = useState<Date>(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [cropEndTime, setCropEndTime] = useState<Date>(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [bestCountForAuto, setBestCountForAuto] = useState(5);
  const [bestSeriesTheme, setBestSeriesTheme] = useState("");
  const [textToVideoTheme, setTextToVideoTheme] = useState("");
  const [entryMode, setEntryMode] = useState("manual");
  const [textToVideoVoiceType, setTextToVideoVoiceType] = useState<VoiceType>(
    VoiceType.Free
  );
  const [bestSeriesVoiceType, setBestSeriesVoiceType] = useState<VoiceType>(
    VoiceType.Free
  );
  const [includeOpening, setIncludeOpening] = useState(true);
  const [includeClosing, setIncludeClosing] = useState(true);
  const [useRank, setUseRank] = useState(true);
  const [useFixedSubtitle, setUseFixedSubtitle] = useState(false);
  const [fixedSubtitle, setFixedSubtitle] = useState("");
  const [bests, setBests] = useState<string[]>([]);
  const { userDetails } = useUser() ?? {};
  const userId = userDetails?.id ?? "";
  const channels = userDetails?.channels ?? [];
  const [channel, setChannel] = useState(() => {
    if (channels.length > 0) return channels[0];
    return "";
  });

  useEffect(() => {
    if (orgTask) {
      setTaskType(orgTask.taskType);
      setVideoLength(orgTask.videoLength);
      if (orgTask.taskType === TaskType.VideoToVideo) {
        setVideoUrl(orgTask.videoToVideo.videoUrl);
        const arrSplitStart = orgTask.videoToVideo.cropStartTime.split(":");
        setCropStartTime(
          new Date(
            new Date().setHours(
              parseInt(arrSplitStart[0], 10),
              parseInt(arrSplitStart[1], 10),
              parseInt(arrSplitStart[2], 10),
              0
            )
          )
        );
        const arrSplitEnd = orgTask.videoToVideo.cropEndTime.split(":");
        setCropEndTime(
          new Date(
            new Date().setHours(
              parseInt(arrSplitEnd[0], 10),
              parseInt(arrSplitEnd[1], 10),
              parseInt(arrSplitEnd[2], 10),
              0
            )
          )
        );
        setUseFixedSubtitle(orgTask.videoToVideo.useFixedSubtitle);
        setFixedSubtitle(orgTask.videoToVideo.fixedSubtitle);
      } else if (orgTask.taskType === TaskType.TextToVideo) {
        setTextToVideoTheme(orgTask.textToVideo.theme);
        setTextToVideoVoiceType(orgTask.textToVideo.voiceType);
      } else if (orgTask.taskType === TaskType.BestSeries) {
        setBestSeriesTheme(orgTask.bestSeries.theme);
        setEntryMode(orgTask.bestSeries.entryMode);
        setBests(orgTask.bestSeries.bests ?? []);
        setBestCountForAuto(orgTask.bestSeries.bestCountForAuto);
        setIncludeOpening(orgTask.bestSeries.includeOpening);
        setIncludeClosing(orgTask.bestSeries.includeClosing);
        setUseRank(orgTask.bestSeries.useRanking);
        setBestSeriesVoiceType(orgTask.bestSeries.voiceType);
      }
    }
  }, [orgTask]);

  function formatDateToTimeString(date: Date): string {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }

  const handleEditTask = async () => {
    try {
      setIsEditing(true);
      if (!userId) {
        alert(t("edit_task.messages.error_user_id"));
        console.error("User ID is not available");
        return;
      }

      if (!orgTask?.id) {
        return;
      }

      if (channels.length === 0) {
        alert(t("edit_task.messages.error_channels_empty"));
        console.error("Channels are empty");
        return;
      }

      const videotask: VideoTask = {
        id: orgTask.id,
        channel: channel,
        taskType: taskType,
        videoLength: videoLength,
        status: TaskStatus.Pending,
        updatedDateTime: new Date().toISOString(),
        textToVideo: {
          theme: textToVideoTheme,
          voiceType: textToVideoVoiceType,
        },
        bestSeries: {
          theme: bestSeriesTheme,
          bests: bests,
          bestsCountForAuto: bestCountForAuto,
          entryMode: entryMode,
          bestCountForAuto: bestCountForAuto,
          includeOpening: includeOpening,
          includeClosing: includeClosing,
          useRanking: useRank,
          voiceType: bestSeriesVoiceType,
        },
        videoToVideo: {
          videoUrl: videoUrl,
          cropStartTime: formatDateToTimeString(cropStartTime),
          cropEndTime: formatDateToTimeString(cropEndTime),
          useFixedSubtitle: useFixedSubtitle,
          fixedSubtitle: fixedSubtitle,
        },
      };

      await updateTask(userId, videotask.id, videotask);
      navigate(`/${currentLang}/task-management`);
    } catch (error) {
      console.error("Failed to edit task:", error);
    } finally {
      setIsEditing(false);
    }
  };

  const onCropStartTimeChanged = (date: Date) => {
    setCropStartTime(date);
    const diff = cropEndTime.getTime() - date.getTime();
    if (diff <= 60000) {
      setVideoLength(VideoLength.Short);
    } else {
      setVideoLength(VideoLength.Long);
    }
  };

  const onCropEndTimeChanged = (date: Date) => {
    setCropEndTime(date);
    const diff = date.getTime() - cropStartTime.getTime();
    if (diff <= 60000) {
      setVideoLength(VideoLength.Short);
    } else {
      setVideoLength(VideoLength.Long);
    }
  };

  const handleCancel = () => {
    navigate(`/${currentLang}/task-management`);
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const items = Array.from(bests);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setBests(items);
  };

  const handleAddBest = () => {
    if (!newBest) return;
    setBests([...bests, newBest]);
    setNewBest("");
  };

  const handleBestCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBestCountForAuto(Number(e.target.value));
  };

  const handleRemoveBest = (index: number) => {
    if (!window.confirm(t("edit_task.messages.confirm_remove_best"))) return;
    const newBests = [...bests];
    newBests.splice(index, 1);
    setBests(newBests);
  };

  return (
    <div className={styles.container}>
      {isEditing && (
        <LoadingSpinner message={t("edit_task.messages.loading")} />
      )}
      <div className={styles.formGroup}>
        <label className={styles.label}>{t("edit_task.labels.channel")}</label>
        <select
          title={t("edit_task.labels.channel")}
          disabled={isEditing}
          value={channel}
          onChange={(e) => setChannel(e.target.value)}
          className={styles.select}
        >
          {channels.map((channel) => (
            <option key={channel} value={channel}>
              {channel}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label}>
          {t("edit_task.labels.task_type")}
        </label>
        <select
          title={t("edit_task.labels.task_type")}
          disabled={isEditing}
          value={taskType}
          onChange={(e) => setTaskType(e.target.value as TaskType)}
          className={styles.select}
        >
          <option value="textToVideo">Script to Video</option>
          <option value="bestSeries">Best Series</option>
        </select>
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label}>
          {t("edit_task.labels.video_length")}
        </label>
        <select
          title={t("edit_task.labels.video_length")}
          disabled={isEditing || taskType === TaskType.VideoToVideo}
          value={videoLength}
          onChange={(e) => setVideoLength(e.target.value as VideoLength)}
          className={styles.select}
        >
          <option value="short">Shorts</option>
          <option value="long">Long Form</option>
        </select>
      </div>
      {taskType === TaskType.VideoToVideo && (
        <div>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              {t("edit_task.labels.youtube_url")}
            </label>
            <input
              disabled={isEditing}
              type="text"
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
              placeholder={t("edit_task.placeholders.enter_youtube_url")}
              className={styles.inputText}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              {t("edit_task.labels.start_time")}
            </label>
            <div className={styles.timeInput}>
              <label className={styles.timeInputLabel}>
                {t("edit_task.labels.start_time")}
                <TimeInput
                  value={cropStartTime}
                  onChange={onCropStartTimeChanged}
                />
              </label>
            </div>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              {t("edit_task.labels.end_time")}
            </label>
            <div className={styles.timeInput}>
              <label className={styles.timeInputLabel}>
                {t("edit_task.labels.end_time")}
                <TimeInput
                  value={cropEndTime}
                  onChange={onCropEndTimeChanged}
                />
              </label>
            </div>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.labelCheckbox}>
              <input
                type="checkbox"
                checked={useFixedSubtitle}
                onChange={(e) => setUseFixedSubtitle(e.target.checked)}
              />
              {t("edit_task.labels.use_fixed_subtitle")}
            </label>
          </div>
          {useFixedSubtitle && (
            <div className={styles.formGroup}>
              <label className={styles.label}>
                {t("edit_task.labels.fixed_subtitle")}
              </label>
              <input
                type="text"
                value={fixedSubtitle}
                onChange={(e) => setFixedSubtitle(e.target.value)}
                placeholder={t("edit_task.placeholders.enter_fixed_subtitle")}
                className={styles.inputText}
              />
            </div>
          )}
        </div>
      )}
      {taskType === TaskType.TextToVideo && (
        <div>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              {t("edit_task.labels.theme")}
            </label>
            <input
              disabled={isEditing}
              type="text"
              value={textToVideoTheme}
              onChange={(e) => setTextToVideoTheme(e.target.value)}
              placeholder={t("edit_task.placeholders.enter_theme")}
              className={styles.inputText}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              {t("edit_task.labels.voice_type")}
            </label>
            <select
              title={t("edit_task.labels.voice_type")}
              value={textToVideoVoiceType}
              onChange={(e) =>
                setTextToVideoVoiceType(e.target.value as VoiceType)
              }
              className={styles.select}
            >
              <option value="free">Free</option>
              <option value="pro">Pro</option>
            </select>
          </div>
        </div>
      )}
      {taskType === TaskType.BestSeries && (
        <div>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              {t("edit_task.labels.theme")}
            </label>
            <input
              title={t("edit_task.labels.theme")}
              disabled={isEditing}
              type="text"
              value={bestSeriesTheme}
              onChange={(e) => setBestSeriesTheme(e.target.value)}
              placeholder={t("edit_task.placeholders.enter_theme")}
              className={styles.inputText}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              {t("edit_task.labels.voice_type")}
            </label>
            <select
              title={t("edit_task.labels.voice_type")}
              value={bestSeriesVoiceType}
              onChange={(e) =>
                setBestSeriesVoiceType(e.target.value as VoiceType)
              }
              className={styles.select}
            >
              <option value="free">Free</option>
              <option value="pro">Pro</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.labelCheckbox}>
              <input
                type="checkbox"
                checked={useRank}
                onChange={(e) => setUseRank(e.target.checked)}
              />
              {t("edit_task.labels.use_rank")}
            </label>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.labelRadio}>
              <input
                disabled={isEditing}
                type="radio"
                value="manual"
                checked={entryMode === "manual"}
                onChange={() => setEntryMode("manual")}
              />
              {t("edit_task.labels.entry_mode.manual")}
            </label>
            <label className={styles.labelRadio}>
              <input
                disabled={isEditing}
                type="radio"
                value="automatic"
                checked={entryMode === "automatic"}
                onChange={() => setEntryMode("automatic")}
              />
              {t("edit_task.labels.entry_mode.automatic")}
            </label>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.labelCheckbox}>
              <input
                type="checkbox"
                checked={includeOpening}
                onChange={(e) => setIncludeOpening(e.target.checked)}
              />
              {t("edit_task.labels.include_opening")}
            </label>
            <label className={styles.labelCheckbox}>
              <input
                type="checkbox"
                checked={includeClosing}
                onChange={(e) => setIncludeClosing(e.target.checked)}
              />
              {t("edit_task.labels.include_closing")}
            </label>
          </div>
          {entryMode === "automatic" && (
            <div className={styles.formGroup}>
              <label className={styles.label}>
                {t("edit_task.labels.best_count")}
              </label>
              <input
                title={t("edit_task.labels.best_count")}
                disabled={isEditing}
                type="number"
                value={bestCountForAuto}
                onChange={handleBestCountChange}
                min={3}
                max={10}
                className={styles.inputText}
              />
            </div>
          )}
          <div className={styles.inputBestContainer}>
            <input
              type="text"
              disabled={isEditing || entryMode !== "manual"}
              value={newBest}
              onChange={(e) => setNewBest(e.target.value)}
              placeholder={t("edit_task.placeholders.add_best_item")}
              className={styles.inputBest}
            />
            <button
              onClick={handleAddBest}
              disabled={isEditing || entryMode !== "manual" || !newBest}
              className={styles.buttonAddBest}
            >
              {t("edit_task.labels.add_best_item")}
            </button>
          </div>
          {entryMode === "manual" && (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="droppable-bests">
                {(provided) => (
                  <table
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={styles.bestsTable}
                  >
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th>Content</th>
                        <th>{t("edit_task.buttons.remove")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bests.map((best, index) => (
                        <Draggable key={best} draggableId={best} index={index}>
                          {(provided, snapshot) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={classNames({
                                draggingStyle: snapshot.isDragging,
                                nonDraggingStyle: !snapshot.isDragging,
                                draggable: true,
                              })}
                            >
                              <td {...provided.dragHandleProps}>{index + 1}</td>
                              <td {...provided.dragHandleProps}>{best}</td>
                              <td>
                                <button
                                  disabled={isEditing}
                                  className={styles.buttonDelete}
                                  onClick={() => handleRemoveBest(index)}
                                >
                                  {t("edit_task.buttons.remove")}
                                </button>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </tbody>
                  </table>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      )}
      <button
        disabled={isEditing}
        onClick={handleEditTask}
        className={styles.button}
      >
        {t("edit_task.buttons.edit")}
      </button>
      <button
        disabled={isEditing}
        onClick={handleCancel}
        className={styles.button}
      >
        {t("edit_task.buttons.cancel")}
      </button>
    </div>
  );
};

export default EditTask;
