import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addTask } from "./firebaseFunctions";
import {
  VideoTask,
  TaskType,
  TaskStatus,
  VideoLength,
  VoiceType,
} from "../types";
import { useUser } from "../contexts/UserContext";
import classNames from "classnames";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import TimeInput from "./TimeInput";
import styles from "./AddTask.module.css";
import LoadingSpinner from "./LoadingSpinner";
import { useTranslation } from "react-i18next"; // i18next import

function formatDateTime(date: Date): string {
  const pad = (num: number) => (num < 10 ? "0" + num : num);

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hour = pad(date.getHours());
  const minute = pad(date.getMinutes());
  const second = pad(date.getSeconds());

  return `${year}${month}${day}${hour}${minute}${second}`;
}

const AddTask = () => {
  const { i18n, t } = useTranslation(); // useTranslation hook 초기화
  const navigate = useNavigate();
  const [isAdding, setIsAdding] = useState(false);
  const [newBest, setNewBest] = useState("");
  const currentLang = i18n.language; // 현재 설정된 언어 가져오기
  const [taskType, setTaskType] = useState<TaskType>(TaskType.TextToVideo);
  const [videoLength, setVideoLength] = useState<VideoLength>(
    VideoLength.Short
  );
  const [videoUrl, setVideoUrl] = useState("");
  const [cropStartTime, setCropStartTime] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [cropEndTime, setCropEndTime] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [bestCountForAuto, setBestCountForAuto] = useState(5);
  const [bestSeriesTheme, setBestSeriesTheme] = useState("");
  const [textToVideoTheme, setTextToVideoTheme] = useState("");
  const [entryMode, setEntryMode] = useState("manual");
  const [includeOpening, setIncludeOpening] = useState(true);
  const [includeClosing, setIncludeClosing] = useState(true);
  const [useRank, setUseRank] = useState(true);
  const [useFixedSubtitle, setUseFixedSubtitle] = useState(false);
  const [fixedSubtitle, setFixedSubtitle] = useState("");
  const [textToVideoVoiceType, setTextToVideoVoiceType] = useState<VoiceType>(
    VoiceType.Free
  );
  const [bestSeriesVoiceType, setBestSeriesVoiceType] = useState<VoiceType>(
    VoiceType.Free
  );

  const [bests, setBests] = useState<string[]>([]);
  const { userDetails } = useUser() ?? {};
  const userId = userDetails?.id ?? "";
  const channels = userDetails?.channels ?? [];

  const [channel, setChannel] = useState(() => {
    if (channels.length > 0) return channels[0];
    return "";
  });

  function formatDateToTimeString(date: Date): string {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }

  const handleAddTask = async () => {
    try {
      setIsAdding(true);
      if (!userId) {
        alert(t("add_task.error_no_user_id")); // 에러 메시지 번역
        console.error(t("add_task.error_no_user_id"));
        return;
      }

      if (channels.length === 0) {
        alert(t("add_task.error_no_channels")); // 에러 메시지 번역
        console.error(t("add_task.error_no_channels"));
        return;
      }

      const creationDate = new Date();
      const yyyyMMddHHmmss = formatDateTime(creationDate);
      const videotask: VideoTask = {
        id: yyyyMMddHHmmss,
        channel: channel,
        taskType: taskType,
        videoLength: VideoLength.Short,
        status: TaskStatus.Pending,
        updatedDateTime: new Date().toISOString(),
        textToVideo: {
          theme: textToVideoTheme,
          voiceType: textToVideoVoiceType,
        },
        bestSeries: {
          theme: bestSeriesTheme,
          bests: bests,
          bestsCountForAuto: bestCountForAuto,
          entryMode: entryMode,
          bestCountForAuto: bestCountForAuto,
          includeOpening: includeOpening,
          includeClosing: includeClosing,
          useRanking: useRank,
          voiceType: bestSeriesVoiceType,
        },
        videoToVideo: {
          videoUrl: videoUrl,
          cropStartTime: formatDateToTimeString(cropStartTime),
          cropEndTime: formatDateToTimeString(cropEndTime),
          useFixedSubtitle: useFixedSubtitle,
          fixedSubtitle: fixedSubtitle,
        },
      };

      await addTask(userId, videotask);
      navigate(`/${currentLang}/task-management`);
    } catch (error) {
      console.error("Failed to add task:", error);
    } finally {
      setIsAdding(false);
    }
  };

  const onCropStartTimeChanged = (date: Date) => {
    setCropStartTime(date);
    const diff = cropEndTime.getTime() - date.getTime();
    if (diff <= 60000) {
      setVideoLength(VideoLength.Short);
    } else {
      setVideoLength(VideoLength.Long);
    }
  };

  const onCropEndTimeChanged = (date: Date) => {
    setCropEndTime(date);
    const diff = date.getTime() - cropStartTime.getTime();
    if (diff <= 60000) {
      setVideoLength(VideoLength.Short);
    } else {
      setVideoLength(VideoLength.Long);
    }
  };

  const handleCancel = () => {
    navigate(`/${currentLang}/task-management`);
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const items = Array.from(bests);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setBests(items);
  };

  const handleAddBest = () => {
    if (!newBest) return;
    setBests([...bests, newBest]);
    setNewBest("");
  };

  const handleBestCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBestCountForAuto(Number(e.target.value));
  };

  const handleRemoveBest = (index: number) => {
    if (!window.confirm(t("add_task.confirm_delete_best_item"))) return;
    const newBests = [...bests];
    newBests.splice(index, 1);
    setBests(newBests);
  };

  return (
    <div className={styles.container}>
      {isAdding && <LoadingSpinner message={t("add_task.loading_message")} />}
      <div className={styles.formGroup}>
        <label className={styles.label}>{t("add_task.channel")}</label>
        <select
          title={t("add_task.channel")}
          disabled={isAdding}
          value={channel}
          onChange={(e) => setChannel(e.target.value)}
          className={styles.select}
        >
          {channels.map((channel) => (
            <option key={channel} value={channel}>
              {channel}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label}>{t("add_task.task_type")}</label>
        <select
          title={t("add_task.task_type")}
          disabled={isAdding}
          value={taskType}
          onChange={(e) => setTaskType(e.target.value as TaskType)}
          className={styles.select}
        >
          <option value="textToVideo">Script to Video</option>
          <option value="bestSeries">Best Series</option>
        </select>
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label}>{t("add_task.video_length")}</label>
        <select
          title={t("add_task.video_length")}
          disabled={isAdding || taskType === TaskType.VideoToVideo}
          value={videoLength}
          onChange={(e) => setVideoLength(e.target.value as VideoLength)}
          className={styles.select}
        >
          <option value="short">{t("add_task.short_form")}</option>
          <option value="long">{t("add_task.long_form")}</option>
        </select>
      </div>

      {taskType === TaskType.VideoToVideo && (
        <div>
          <div className={styles.formGroup}>
            <label className={styles.label}>{t("add_task.video_url")}</label>
            <input
              disabled={isAdding}
              type="text"
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
              placeholder={t("add_task.video_url_placeholder")}
              className={styles.inputText}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>{t("add_task.start_time")}</label>
            <div className={styles.timeInput}>
              <label className={styles.timeInputLabel}>
                {t("add_task.start_time")}
                <TimeInput
                  value={cropStartTime}
                  onChange={onCropStartTimeChanged}
                />
              </label>
            </div>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>{t("add_task.end_time")}</label>
            <div className={styles.timeInput}>
              <label className={styles.timeInputLabel}>
                {t("add_task.end_time")}
                <TimeInput
                  value={cropEndTime}
                  onChange={onCropEndTimeChanged}
                />
              </label>
            </div>
          </div>

          <div className={styles.formGroup}>
            <label className={styles.labelCheckbox}>
              <input
                type="checkbox"
                checked={useFixedSubtitle}
                onChange={(e) => setUseFixedSubtitle(e.target.checked)}
              />
              {t("add_task.use_fixed_subtitle")}
            </label>
          </div>
          {useFixedSubtitle && (
            <div className={styles.formGroup}>
              <label className={styles.label}>
                {t("add_task.fixed_subtitle")}
              </label>
              <input
                type="text"
                value={fixedSubtitle}
                onChange={(e) => setFixedSubtitle(e.target.value)}
                placeholder={t("add_task.fixed_subtitle_placeholder")}
                className={styles.inputText}
              />
            </div>
          )}
        </div>
      )}
      {taskType === TaskType.TextToVideo && (
        <div>
          <div className={styles.formGroup}>
            <label className={styles.label}>{t("add_task.theme")}</label>
            <input
              disabled={isAdding}
              type="text"
              value={textToVideoTheme}
              onChange={(e) => setTextToVideoTheme(e.target.value)}
              placeholder={t("add_task.theme_placeholder")}
              className={styles.inputText}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>{t("add_task.voice_type")}</label>
            <select
              title={t("add_task.voice_type")}
              value={textToVideoVoiceType}
              onChange={(e) =>
                setTextToVideoVoiceType(e.target.value as VoiceType)
              }
              className={styles.select}
            >
              <option value="free">{t("add_task.voice_type_free")}</option>
              <option value="pro">{t("add_task.voice_type_pro")}</option>
            </select>
          </div>
        </div>
      )}
      {taskType === TaskType.BestSeries && (
        <div>
          <div className={styles.formGroup}>
            <label className={styles.label}>{t("add_task.theme")}</label>
            <input
              disabled={isAdding}
              type="text"
              value={bestSeriesTheme}
              onChange={(e) => setBestSeriesTheme(e.target.value)}
              placeholder={t("add_task.theme_placeholder")}
              className={styles.inputText}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>{t("add_task.voice_type")}</label>
            <select
              title={t("add_task.voice_type")}
              value={bestSeriesVoiceType}
              onChange={(e) =>
                setBestSeriesVoiceType(e.target.value as VoiceType)
              }
              className={styles.select}
            >
              <option value="free">{t("add_task.voice_type_free")}</option>
              <option value="pro">{t("add_task.voice_type_pro")}</option>
            </select>
          </div>

          <div className={styles.formGroup}>
            <label className={styles.labelCheckbox}>
              <input
                type="checkbox"
                checked={useRank}
                onChange={(e) => setUseRank(e.target.checked)}
              />
              {t("add_task.use_ranking")}
            </label>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.labelRadio}>
              <input
                disabled={isAdding}
                type="radio"
                value="manual"
                checked={entryMode === "manual"}
                onChange={() => setEntryMode("manual")}
              />
              {t("add_task.manual_entry")}
            </label>
            <label className={styles.labelRadio}>
              <input
                disabled={isAdding}
                type="radio"
                value="automatic"
                checked={entryMode === "automatic"}
                onChange={() => setEntryMode("automatic")}
              />
              {t("add_task.automatic_entry")}
            </label>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.labelCheckbox}>
              <input
                type="checkbox"
                checked={includeOpening}
                onChange={(e) => setIncludeOpening(e.target.checked)}
              />
              {t("add_task.include_opening")}
            </label>
            <label className={styles.labelCheckbox}>
              <input
                type="checkbox"
                checked={includeClosing}
                onChange={(e) => setIncludeClosing(e.target.checked)}
              />
              {t("add_task.include_closing")}
            </label>
          </div>
          {entryMode === "automatic" && (
            <div className={styles.formGroup}>
              <label className={styles.label}>
                {t("add_task.best_count_for_auto")}
              </label>
              <input
                title={t("add_task.best_count_for_auto")}
                disabled={isAdding}
                type="number"
                value={bestCountForAuto}
                onChange={handleBestCountChange}
                min={3}
                max={10}
                className={styles.inputText}
              />
            </div>
          )}
          <div className={styles.inputBestContainer}>
            <input
              type="text"
              disabled={isAdding || entryMode !== "manual"}
              value={newBest}
              onChange={(e) => setNewBest(e.target.value)}
              placeholder={t("add_task.add_best_item")}
              className={styles.inputBest}
            />
            <button
              onClick={handleAddBest}
              disabled={isAdding || entryMode !== "manual" || !newBest}
              className={styles.buttonAddBest}
            >
              {t("add_task.add_best_item")}
            </button>
          </div>
          {entryMode === "manual" && (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="droppable-bests">
                {(provided) => (
                  <table
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={styles.bestsTable}
                  >
                    <thead>
                      <tr>
                        <th>{t("add_task.rank")}</th>
                        <th>{t("add_task.content")}</th>
                        <th>{t("add_task.remove_best_item")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bests.map((best, index) => (
                        <Draggable key={best} draggableId={best} index={index}>
                          {(provided, snapshot) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={classNames({
                                draggingStyle: snapshot.isDragging,
                                nonDraggingStyle: !snapshot.isDragging,
                                draggable: true,
                              })}
                            >
                              <td {...provided.dragHandleProps}>{index + 1}</td>
                              <td {...provided.dragHandleProps}>{best}</td>
                              <td>
                                <button
                                  disabled={isAdding}
                                  className={styles.buttonDelete}
                                  onClick={() => handleRemoveBest(index)}
                                >
                                  {t("add_task.remove_best_item")}
                                </button>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </tbody>
                  </table>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      )}
      <button
        disabled={isAdding}
        onClick={handleAddTask}
        className={styles.button}
      >
        {t("add_task.add_task_button")}
      </button>
      <button
        disabled={isAdding}
        onClick={handleCancel}
        className={styles.button}
      >
        {t("add_task.cancel_button")}
      </button>
    </div>
  );
};

export default AddTask;
